import React, {Component, useEffect, useState} from 'react';
import Header from "../../components/header";
// import Search from "../../components/search";
import {Swiper, SwiperSlide} from "swiper/react";
import {InputItem} from "antd-mobile";
import {Link} from "react-router-dom";
import Advert from "../../components/advert";
import $axios from "../../commons/ajax";
const language = localStorage.getItem('languageType')


const What = (props) => {

    const [advertsListWhatTop, setAdvertsListWhatTop] = useState([]);
    const [advertsListWhatFooter, setAdvertsListWhatFooter] = useState([]);
    const [whatTab, setWhatTab] = useState([])

    // 获取广告
    const getAdvertsWhatsonTop = async () => {
        $axios.get('/device/advertisingPosition/whatsonTop').then(
            response => {
                if(response.data.data !== null) {
                    console.log('广告返回的数据', response.data.data)
                    setAdvertsListWhatTop(response.data.data)
                }else{

                }
            },
            error => {console.log('请求失败', error)}
        )
    }

    // 获取广告
    const getAdvertsWhatsonFooter = async () => {
        $axios.get('/device/advertisingPosition/whatsonFooter').then(
            response => {
                if(response.data.data !== null) {
                    console.log('广告返回的数据', response.data.data)
                    setAdvertsListWhatFooter(response.data.data)

                    const group = response.data.data.groups
                    const newTabs = []
                    if(group && group.length > 0){
                         console.log('123')
                        group.map((item) => {
                            if(item.label){
                                if(language === 'en'){
                                    newTabs.push({title: item.label.en})
                                }else if(language === 'cn'){
                                    newTabs.push({title: item.label.cn})
                                }else if (language === 'mas'){
                                    newTabs.push({title: item.label.mas})
                                }else if(language === 'தமிழ்'){
                                    newTabs.push({title: item.label.தமிழ்})
                                }else {
                                    newTabs.push({title: item.label.en})
                                }
                            }
                            console.log('每一个', item)
                        })
                        setWhatTab(newTabs)
                    }
                }else{

                }
            },
            error => {console.log('请求失败', error)}
        )
    }

    useEffect(() =>{
        getAdvertsWhatsonTop()
        getAdvertsWhatsonFooter()
    },[])

    return (
        <div>
            {/* 公共头部 */}
            <Header/>

            <div className="container">
                <div className="rewardsBox">
                    {/* what广告位 */}
                    <Advert advertsData={advertsListWhatTop} />

                    {/* 搜索 */}
                    {/*<Search />*/}
                    <Link to="/shop" className="searchBox">
                        <div className="searchStyle">
                            <div className="searchStyle_icon"><img src={require("../../assets/images/searchIcon.png").default} alt={""} /></div>
                            <InputItem
                                className="searchStyle_input"
                                type="phone"
                                placeholder="Search"
                                // onErrorClick={this.onErrorClick}
                                // onChange={this.props.a(keyword.value)}
                            />
                            <div className="searchStyle_say" />
                        </div>
                    </Link>

                    {/* what广告位 */}
                    <Advert advertsData={advertsListWhatFooter} whatTab={whatTab}/>

                </div>
            </div>
        </div>
    );
}

export default What
