import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.scss';
import App from './App';
import { Provider } from 'react-redux'
import store from './store'
import reportWebVitals from './reportWebVitals';
import 'antd-mobile/dist/antd-mobile.css';
import "swiper/swiper-bundle.css";
// 路由使用 history模式
import { BrowserRouter } from 'react-router-dom';
// swiper 引入
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper';
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}><App /></Provider>
    </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
