import React, {Component} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Icon, Modal} from "antd-mobile";
const language = localStorage.getItem('languageType')

export default class DineSwiper extends Component {
    state = {
        modal1: false,
        storeDetail: {
            photo: require('../../assets/images/dineIm1.png').default,
            store_name: {en:'dfe'},
            opening_hours: {en:'9:00'},
            categories_names: '',
            store_description: {en:'dfdksljfsl'}
        }
    }

    // 弹窗关闭
    onClose = () => {
        this.setState({modal1:false})
    }

    handelToDetail = () => {
        this.setState({modal1:true})
    }

    render() {
        const {dineSwiperList} = this.props
        const {modal1,storeDetail} = this.state
        return (
            <div>
                {/* 轮播 */}
                <div className="DineSwiper">
                    <Swiper
                        className="swiperStyle"
                        slidesPerView={2}
                        loop={false} // 是否循环
                    >
                        {
                            dineSwiperList.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className="PikList">
                                        <img src={item.bg} alt={""} className="img1"/>
                                        <div className="cont">
                                            <div className="cont_top">
                                                <img src={item.logo} alt={""} />
                                                <img src={item.mc} alt={""} />
                                            </div>
                                            <div className="cont_center">
                                                {item.mx}
                                            </div>
                                            <div className="cont_btn" onClick={this.handelToDetail}>
                                                Click me to find out more
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>


                {/* 弹窗 */}
                <Modal
                    className="detailModal"
                    visible={modal1}
                    transparent
                    maskClosable={false}
                    onClose={this.onClose}
                    title=""
                    // footer={[{ text: 'Ok', onPress: () => { console.log('ok'); this.onClose('modal1')(); } }]}
                    // wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                    // afterClose={() => { alert('afterClose'); }}
                >
                    <div className="close" onClick={this.onClose}><Icon type="cross" /></div>

                    {/* 图片 */}
                    <div className="detailModal_img">
                        <img src={storeDetail.photo} alt={""} />
                    </div>

                    {/* logo和名称*/}
                    <div className="detailModal_top">
                        <img src={storeDetail.photo} alt={""} />
                        <div>
                            <div className="name">
                                <>
                                    {language === 'en' ? storeDetail.store_name.en :
                                        language === 'cn' ? storeDetail.store_name.cn :
                                            language === 'mas' ? storeDetail.store_name.mas :
                                                language === 'தமிழ்' ? storeDetail.store_name.தமிழ் : <>else</>
                                    }
                                </>
                            </div>
                            {/*<div className="time">01-01</div>*/}
                        </div>
                    </div>

                    {/* 内容 */}
                    <div className="detailModal_nr">
                        <div className="lf">
                            <div className="lf_l">
                                operating hours
                                <span>
                                {language === 'en' ? storeDetail.opening_hours.en :
                                    language === 'cn' ? storeDetail.opening_hours.cn :
                                        language === 'mas' ? storeDetail.opening_hours.mas :
                                            language === 'தமிழ்' ? storeDetail.opening_hours.தமிழ் : <>else</>
                                }
                            </span>
                            </div>
                            <div className="lf_l">
                                category<span>{storeDetail.categories_names}</span>
                            </div>
                            {/*<div className="lf_l">*/}
                            {/*    contact<span>+65 6835 7297</span>*/}
                            {/*</div>*/}
                        </div>
                        <div className="rg">
                            {language === 'en' ? storeDetail.store_description.en :
                                language === 'cn' ? storeDetail.store_description.cn :
                                    language === 'mas' ? storeDetail.store_description.mas :
                                        language === 'தமிழ்' ? storeDetail.store_description.தமிழ் : <>else</>
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
