import React, {useEffect, useState} from 'react';
import Header from '../../components/header/index'
import {Link } from "react-router-dom"
import $axios from "../../commons/ajax";
import {useHistory} from 'react-router-dom';
import { InputItem } from "antd-mobile";
import Advert from '../../components/advert/index'


const Home = (props) => {
    const history = useHistory();
    const [enter] = useState(window.localStorage.getItem('enterTag'))
    const [advertsListHomeTop, setAdvertsListHomeTop] = useState([])
    const [advertisingListTwo, setAdvertisingListTwo] = useState([])

    // 获取设备
    const getDevice = () => {
        if(enter !== 'firstEnter'){
            window.localStorage.setItem('enterTag', '')
            $axios.get('/device/detail').then(
                response => {
                    if(response.data.data === null) {
                        history.push(`/register`)
                    }else{
                        history.push(`/welcome`)
                    }
                },
                error => {console.log('请求失败', error)}
            )
        }
    }

    // 获取广告
    const getAdverts1 = async () => {
        $axios.get('/device/advertisingPosition/homeTop').then(
            response => {
                if(response.data.data !== null) {
                    console.log('广告返回的数据', response.data.data)
                    setAdvertsListHomeTop(response.data.data)
                }else{

                }
            },
            error => {console.log('请求失败', error)}
        )
    }

    // 获取广告
    const getAdverts2 = async () => {
        $axios.get('/device/advertisingPosition/homeFooter').then(
            response => {
                if(response.data.data !== null) {
                    console.log('广告返回的数据', response)
                    setAdvertisingListTwo(response.data.data)
                }else{
                }
            },
            error => {console.log('请求失败', error)}
        )
    }

    useEffect(() =>{
        getDevice()
        getAdverts1()
        getAdverts2()
    },[enter])
    return (
        <div>
            {/* 公共头部 */}
            <Header/>

            <div className="container">
                {/* 图片轮播 */}
                <Advert advertsData={advertsListHomeTop}/>

                {/* 搜索 */}
                {/*<Search />*/}
                <Link to="/shop" className="searchBox">
                    <div className="searchStyle">
                        <div className="searchStyle_icon"><img src={require("../../assets/images/searchIcon.png").default} alt={""} /></div>
                        <InputItem
                            className="searchStyle_input"
                            type="phone"
                            placeholder="Search"
                            // onErrorClick={this.onErrorClick}
                            // onChange={this.props.a(keyword.value)}
                        />
                        <div className="searchStyle_say" />
                    </div>
                </Link>


                {/*  类别列表  */}
                <Advert advertsData={advertisingListTwo}/>

            </div>
        </div>
    );
}


export default (Home)
