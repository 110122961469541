import React, {useEffect, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Icon, Modal, Tabs} from "antd-mobile";
import {Link} from 'react-router-dom'
const language = localStorage.getItem('languageType')


const Advert = (props) => {
    const {advertsData, whatTab} = props

    const [advertDetail, setAdvertDetail] = useState({
        ad_detail: {
            title: {},
            sub_title: {},
            sub_subtitle: {},
            content: {},
            action_button: {}
        }
    })
    const [modal1, setModal1] = useState(false)
    const [detailTab] = useState([{title: 'ABOUT'}, {title: 'QRCODE'},])
    const [modalMap, setModalMap] = useState(false)
    const [location, setLocation] = useState('')

    // 弹窗打开
    const handleShowModal = (item) => {
        setModal1(true)
        setAdvertDetail(item)
    }

    // 弹窗关闭
    const onClose = () => {
        setModal1(false)
    }

    // 地图弹窗关闭
    const onCloseModalMap = () => {
        setModalMap(false)
    }

    // 展示地图
    const handleShowMap = (location) => {
        setModalMap(true)
        setLocation(location)
    }

    return (
        <div>
            {
                advertsData.type === 1 ?
                    <>
                        <Swiper
                            className="swiperHomeStyle"
                            spaceBetween={50}
                            slidesPerView={1}
                            initialSlide={1} // 初始化显示哪一个
                            loop={true} // 是否循环
                            navigation
                            pagination={{clickable: true}}
                            autoplay={{
                                delay: 4000,
                                disableOnInteraction: false
                            }}
                            // onSlideChange={() => console.log('slide change')}
                            // onSwiper={(swiper) => console.log(swiper)}
                            threshold={40}
                        >
                            {advertsData.advertising && advertsData.advertising.length>0 ?
                                <>
                                    {advertsData.advertising.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <img src={item.photo_src} alt="" onClick={item.content_type === '4' ? ()=>{window.location.href=item.content} : () => handleShowModal(item)} />
                                        </SwiperSlide>
                                    ))}
                                </> : <div className="noEvents" style={{height:'3.08rem'}}>No Events</div>
                            }
                        </Swiper>
                    </>
                    : advertsData.type === 2 ?
                    <>
                        <div className="homeList">
                            {advertsData.advertising && advertsData.advertising.length > 0 ?
                                advertsData.advertising.map((item, index) => (
                                    <div className="homeList_l" key={index} onClick={item.content_type === '4' ? ()=>{window.location.href=item.content} : () => handleShowModal(item) }>
                                        <div className="homeList_l_img">
                                            <img src={item.photo_src} alt={""}/>
                                        </div>
                                        <div className="homeList_l_text">
                                            {language === 'en' ? item.title.en :
                                                language === 'cn' ? item.title.cn :
                                                    language === 'mas' ? item.title.mas :
                                                        language === 'தமிழ்' ? item.title.தமிழ் : <>else</>
                                            }
                                        </div>
                                    </div>
                                ))
                                : <div className="noEvents" style={{width: '100%', height:'3.08rem'}}>No Events</div>
                            }
                        </div>
                    </>
                    : advertsData.type === 3 ?
                        <>
                            <div className="rewardsBox_banner">
                                {/* 图片轮播 */}
                                <Swiper
                                    className="swiperWhatStyle"
                                    spaceBetween={50}
                                    slidesPerView={1}
                                    initialSlide={1} // 初始化显示哪一个
                                    loop={true} // 是否循环
                                    navigation={{
                                        nextEl: '.swiper-banner-button-next',
                                        prevEl: '.swiper-banner-button-prev',
                                    }}
                                    pagination={{ clickable: true }}
                                    autoplay={{
                                        delay: 4000,
                                        disableOnInteraction: false
                                    }}
                                    threshold={40}
                                >
                                    {advertsData.advertising && advertsData.advertising.length>0 ?
                                        <>
                                            {advertsData.advertising.map((item, index) => (
                                                <SwiperSlide key={index}>
                                                    {item.content_type === '4' ?
                                                        <Link to={item.content}><img src={item.photo_src} alt="" /></Link>
                                                        : <img src={item.photo_src} alt="" onClick={() => handleShowModal(item)} />
                                                    }
                                                </SwiperSlide>
                                            ))}
                                        </> : <div className="noEvents" style={{height:'3rem'}}>No Events</div>
                                    }
                                </Swiper>
                                <div className="swiper-banner-button-prev"></div>
                                <div className="swiper-banner-button-next"></div>
                            </div>
                        </>
                    : advertsData.type === 4 ?
                        <>
                            {/*/!* Tabs *!/*/}
                            <div className="whatBox">
                                <Tabs
                                    tabs={whatTab}
                                    tabBarPosition={'top'}
                                    initialPage={0}
                                    animated={false}
                                    useOnPan={false}
                                    swipeable={false}
                                >
                                    {advertsData.groups && advertsData.groups.length > 0 ?
                                            advertsData.groups.map((item, index) => (
                                                <div className="whatBox_list" key={index}>
                                                    {/* 轮播 */}
                                                    <div className="whatSwiper">
                                                        <Swiper
                                                            className="swiperStyle"
                                                            slidesPerView={item.list.length >= 3 ? 3 : item.list.length}
                                                            loop={true} // 是否循环
                                                            navigation={{
                                                                nextEl: ".swiper-button-next",
                                                                prevEl: ".swiper-button-prev",
                                                            }}
                                                        >
                                                            {item.list && item.list.length > 0 ? (
                                                                <>
                                                                    {item.list.map((item2, index2) => {
                                                                        return (
                                                                            <SwiperSlide key={index2}>
                                                                                <div className="SwiEveryList" onClick={() => handleShowModal(item2)}>
                                                                                    <div className="SwiEveryList_top">
                                                                                        <img src={item2.photo_src} alt="" />
                                                                                        <div className="SwiEveryList_tit">
                                                                                            {language === 'en' ? item2.title.en :
                                                                                                language === 'cn' ? item2.title.cn :
                                                                                                    language === 'mas' ? item2.title.mas :
                                                                                                        language === 'தமிழ்' ? item2.title.தமிழ் : <>else</>
                                                                                            }
                                                                                        </div>
                                                                                        {/*<div className="SwiEveryList_time">{item.time}</div>*/}
                                                                                    </div>
                                                                                    <div className="SwiEveryList_btn">
                                                                                        <img src={require("../../assets/images/what_runing.png").default} alt={""}/>
                                                                                        Way Finding
                                                                                    </div>
                                                                                </div>
                                                                            </SwiperSlide>
                                                                        );
                                                                    })}
                                                                </>
                                                            ) : (
                                                                <div className="noEvents" style={{height:'2.18rem'}}>No Events</div>
                                                            )}
                                                        </Swiper>
                                                        <div className="swiper-button-prev" />
                                                        <div className="swiper-button-next" />
                                                    </div>
                                                </div>
                                            ))
                                        : ''
                                    }
                                </Tabs>
                            </div>
                        </>
                    : advertsData.type === 5 ?
                        <>
                            <div className="topImg">
                                {advertsData.advertising && advertsData.advertising.length>0 ?
                                    <>
                                        <img
                                            src={advertsData.advertising[0].photo_src}
                                            alt={""}
                                            onClick={advertsData.advertising[0].content_type === '4' ? ()=>{window.location.href=advertsData.advertising[0].content} : () => handleShowModal(advertsData.advertising[0]) }
                                        />
                                    </> : <div className="noEvents" style={{height:'3rem'}}>No Events</div>
                                }
                                {/*<div className="nr">mallName<br />Rewards<br />Systems</div>*/}
                            </div>
                        </>
                        : advertsData.type === 6 ?
                            <>
                                <div className="rewardsSwiper">
                                    <Swiper
                                        className="swiperStyle"
                                        slidesPerView={advertsData.advertising.length >= 3 ? 3 : advertsData.advertising.length}
                                        loop={true} // 是否循环
                                        navigation={{
                                            nextEl: '.swiper-button-next',
                                            prevEl: '.swiper-button-prev',
                                        }}
                                    >
                                        {advertsData.advertising && advertsData.advertising.length > 0 ?
                                            advertsData.advertising.map((item,index) => (
                                                <SwiperSlide key={index}>
                                                    <div className="SwiList">
                                                        <img src={item.photo_src} alt=""/>
                                                        <div className="SwiList_tit">
                                                            {language === 'en' ? item.title.en :
                                                                language === 'cn' ? item.title.cn :
                                                                    language === 'mas' ? item.title.mas :
                                                                        language === 'தமிழ்' ? item.title.தமிழ் : <>else</>
                                                            }
                                                        </div>
                                                        <div className="SwiList_btn" onClick={item.content_type === '4' ? ()=>{window.location.href=item.content} : () => handleShowModal(item) }>Click me to find out more</div>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                            : <div className="rewardsNo">NO EVENTS</div>
                                        }
                                    </Swiper>
                                    <div className="swiper-button-prev"></div>
                                    <div className="swiper-button-next"></div>
                                </div>
                            </>
                            : ''
            }


            {/* What 和 Rewards 和 store 详情弹窗 */}
            <Modal
                className="detailModal"
                visible={modal1}
                transparent
                maskClosable={false}
                onClose={onClose}
                title=""
                // footer={[{ text: 'Ok', onPress: () => { console.log('ok'); this.onClose('modal1')(); } }]}
                // wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                // afterClose={() => { alert('afterClose'); }}
            >
                <div className="close" onClick={onClose}><Icon type="cross"/></div>

                {/* 图片 */}
                <div className="detailModal_img">
                    <img
                        src={advertDetail.content_type === '1' ? advertDetail.ad_detail.thumbnail : advertDetail.ad_detail.image_banner_src}
                        alt={""}
                    />
                </div>

                {
                    advertDetail.content_type === '1' ?
                        <>
                            {/* logo和名称*/}
                            <div className="detailModal_top">
                                <img src={advertDetail.ad_detail.photo} alt={""} />
                                <div>
                                    <div className="name">
                                        <>
                                            {language === 'en' ? advertDetail.ad_detail.store_name.en :
                                                language === 'cn' ? advertDetail.ad_detail.store_name.cn :
                                                    language === 'mas' ? advertDetail.ad_detail.store_name.mas :
                                                        language === 'தமிழ்' ? advertDetail.ad_detail.store_name.தமிழ் : <></>
                                            }
                                        </>
                                    </div>
                                    <div className="time">
                                        <>
                                            {language === 'en' ? advertDetail.ad_detail.unit_number.en :
                                                language === 'cn' ? advertDetail.ad_detail.unit_number.cn :
                                                    language === 'mas' ? advertDetail.ad_detail.unit_number.mas :
                                                        language === 'தமிழ்' ? advertDetail.ad_detail.unit_number.தமிழ் : <></>
                                            }
                                        </>
                                    </div>
                                </div>
                            </div>
                            {/* 内容 */}
                            <div className="detailModal_nr">
                                <div className="lf">
                                    <div className="lf_l">
                                        {/*operating hours*/}
                                        <span>
                                            {language === 'en' ? advertDetail.ad_detail.opening_hours.en :
                                                language === 'cn' ? advertDetail.ad_detail.opening_hours.cn :
                                                    language === 'mas' ? advertDetail.ad_detail.opening_hours.mas :
                                                        language === 'தமிழ்' ? advertDetail.ad_detail.opening_hours.தமிழ் : <></>
                                            }
                                        </span>
                                    </div>
                                    <div className="lf_l">
                                        {/*category*/}
                                        <span>{advertDetail.ad_detail.categories_names}</span>
                                    </div>
                                    <div className="lf_l">
                                        {/*contact*/}
                                        <span>
                                            {language === 'en' ? advertDetail.ad_detail.sub_subtitle.en :
                                                language === 'cn' ? advertDetail.ad_detail.sub_subtitle.cn :
                                                    language === 'mas' ? advertDetail.ad_detail.sub_subtitle.mas :
                                                        language === 'தமிழ்' ? advertDetail.ad_detail.sub_subtitle.தமிழ் : <></>
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="rg">
                                    <>
                                        {language === 'en' ? advertDetail.ad_detail.content.en :
                                            language === 'cn' ? advertDetail.ad_detail.content.cn :
                                                language === 'mas' ? advertDetail.ad_detail.content.mas :
                                                    language === 'தமிழ்' ? advertDetail.ad_detail.content.தமிழ் : <></>
                                        }
                                    </>
                                </div>

                            </div>
                        </>

                    :
                        <div className="detailModal_rewards">
                            <div className="lf">
                                <p className="tit">
                                    {/*{advertDetail.ad_detail.title}*/}
                                    {language === 'en' ? advertDetail.ad_detail.title.en :
                                        language === 'cn' ? advertDetail.ad_detail.title.cn :
                                            language === 'mas' ? advertDetail.ad_detail.title.mas :
                                                language === 'தமிழ்' ? advertDetail.ad_detail.title.தமிழ் : <></>
                                    }
                                </p>
                                <p>
                                    {/*{advertDetail.ad_detail.sub_title}*/}
                                    {language === 'en' ? advertDetail.ad_detail.sub_title.en :
                                        language === 'cn' ? advertDetail.ad_detail.sub_title.cn :
                                            language === 'mas' ? advertDetail.ad_detail.sub_title.mas :
                                                language === 'தமிழ்' ? advertDetail.ad_detail.sub_title.தமிழ் : <></>
                                    }
                                </p>
                                <p className="te">
                                    {/*{advertDetail.ad_detail.sub_subtitle}*/}
                                    {language === 'en' ? advertDetail.ad_detail.sub_subtitle.en :
                                        language === 'cn' ? advertDetail.ad_detail.sub_subtitle.cn :
                                            language === 'mas' ? advertDetail.ad_detail.sub_subtitle.mas :
                                                language === 'தமிழ்' ? advertDetail.ad_detail.sub_subtitle.தமிழ் : <></>
                                    }
                                </p>
                            </div>
                            <div className="rg">
                                {
                                    advertDetail.content_type === '3' ?
                                        <Tabs
                                            tabs={detailTab}
                                            initialPage={0}
                                            animated={false}
                                            useOnPan={false}
                                        >
                                            <div>
                                                {/*{advertDetail.ad_detail.content}*/}
                                                {language === 'en' ? advertDetail.ad_detail.content.en :
                                                    language === 'cn' ? advertDetail.ad_detail.content.cn :
                                                        language === 'mas' ? advertDetail.ad_detail.content.mas :
                                                            language === 'தமிழ்' ? advertDetail.ad_detail.content.தமிழ் : <></>
                                                }
                                            </div>
                                            <div>
                                                { advertDetail.ad_detail.qrcode_src ?
                                                    <img src={advertDetail.ad_detail.qrcode_src} alt={""} style={{width: '2rem'}}/>
                                                    : ''
                                                }
                                            </div>
                                        </Tabs>
                                    :
                                        <>
                                            {/*{advertDetail.ad_detail.content}*/}
                                            {language === 'en' ? advertDetail.ad_detail.content.en :
                                                language === 'cn' ? advertDetail.ad_detail.content.cn :
                                                    language === 'mas' ? advertDetail.ad_detail.content.mas :
                                                        language === 'தமிழ்' ? advertDetail.ad_detail.content.தமிழ் : <></>
                                            }
                                        </>
                                }

                            </div>
                        </div>
                }


                <div className="detailModal_btn"
                    onClick={
                        language === 'en' ? advertDetail.content_type === '1' ? () => handleShowMap(advertDetail.ad_detail.location) : ()=>{window.location.href=advertDetail.ad_detail.link.en  } :
                            language === 'cn' ? advertDetail.content_type === '1' ? () => handleShowMap(advertDetail.ad_detail.location) : ()=>{window.location.href=advertDetail.ad_detail.link.cn} :
                                language === 'mas' ? advertDetail.content_type === '1' ? () => handleShowMap(advertDetail.ad_detail.location) : ()=>{window.location.href=advertDetail.ad_detail.link.mas} :
                                    language === 'தமிழ்' ? advertDetail.content_type === '1' ? () => handleShowMap(advertDetail.ad_detail.location) : ()=>{window.location.href=advertDetail.ad_detail.link.தமிழ்} : <></>
                        }
                >
                    {language === 'en' ? advertDetail.ad_detail.action_button.en :
                        language === 'cn' ? advertDetail.ad_detail.action_button.cn :
                            language === 'mas' ? advertDetail.ad_detail.action_button.mas :
                                language === 'தமிழ்' ? advertDetail.ad_detail.action_button.தமிழ் : <></>
                    }
                </div>
            </Modal>


            {/* 弹窗 */}
            <Modal
                className="mapModal"
                visible={modalMap}
                transparent
                maskClosable={true}
                onClose={onCloseModalMap}
                title=""
            >
                <div className="close" onClick={onCloseModalMap}><Icon type="cross" /></div>
                <img src={location} alt={""} />
            </Modal>
        </div>
    )
}
export default (Advert)
