import React, { useEffect, useState } from "react";
import { TabBar } from "antd-mobile";
import Home from "../views/home";
import { Route } from "react-router-dom";
import Map from "../views/map";
import Shop from "../views/shop";
import What from "../views/what";
import Help from "../views/help";
import Call from "../views/help/call";
import Rewards from "../views/rewards";
import Info from "../views/info";
import Dine from "../views/home/dine";
// import InfoAmenities from "../views/info/amenities";
// import InfoParking from "../views/info/parking";
// import InfoFaq from "../views/info/faq";
import { Provider } from "react-redux";
import store from "../store";
import $axios from "../commons/ajax";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import blockImg from "../assets/images/barrier.png";
import socket from '../commons/socket'

const MainLayout = () => {
  const pathname = window.location.pathname;
  const [mapBar, setMapBar] = useState(false);
  const [shopBar, setShopBar] = useState(false);
  const [whatBar, setWhatBar] = useState(false);
  const [helpBar, setHelpBar] = useState(false);
  const [rewardsBar, setRewardsBar] = useState(false);
  const [infoBar, setInfoBar] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [popMessage, setPopMessage] = React.useState({title: '', description: '', status: '', duration: ''})

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #000",
      boxShadow: theme.shadows[5],
      height: "400px",
      width: "400px",
      padding: theme.spacing(5, 4, 3),
    },
  }));

  const classes = useStyles();
  const handleOpen = () => {
    socket.on('connect', function(data){

    })

    // try {
    //   $axios.get("/device/deviceObstacleInform").then(
    //       (response) => {
    //         if (response.data.data !== null) {
    //           setPopMessage(response.data.data[0])
    //           handleClose(5)
    //           setOpen(true);
    //         }
    //         // startTimer()
    //       },
    //       (error) => { return error }
    //   );
    // }catch (e) {
    //   startTimer()
    // }
  };

  const handleClose = (ms) => {
    let maxTime = ms //按秒计算
    let timer = null
    setTimeout(function f(){
      if (maxTime >= 0) {
        --maxTime
      } else{
        clearTimeout(timer)
        setOpen(false)
        return
      }
      timer = setTimeout(f,1000)
    },1000)
  };

  const getMenuBar = () => {
    $axios.get("/device/menuSetting").then(
      (response) => {
        if (response.data.data !== null) {
          console.log("菜单数据", response);
          const menu = response.data.data.list;
          if (menu.length > 0) {
            setMapBar(menu[5].display);
            setShopBar(menu[4].display);
            setWhatBar(menu[3].display);
            setHelpBar(menu[2].display);
            setRewardsBar(menu[1].display);
            setInfoBar(menu[0].display);
          }
        } else {
        }
      },
      (error) => {
        console.log("请求失败", error);
      }
    );
  };


  useEffect(() => {
    getMenuBar();
    handleOpen()
  }, []);

  return (
    <Provider store={store}>
      <div
        style={{
          position: "fixed",
          height: "100%",
          width: "100%",
          top: 0,
          background: "#ffffff",
        }}
      >
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h2 style={{ fontSize: "35px" }} id="transition-modal-title">
                { popMessage.title }
              </h2>
              <img
                src={require("../assets/images/barrier.png").default}
                alt={""}
                style={{ width: "150px", height: "150px", paddingTop: "20px", paddingBottom: "20px" }}
              />
              <p style={{ fontSize: "27px", paddingBottom: "20px"}} id="transition-modal-description">{popMessage.status}</p>
              <p style={{ fontSize: "20px" }} id="transition-modal-description">{popMessage.description}</p>
            </div>
          </Fade>
        </Modal>
        {/*<button type="button" onClick={handleOpen}>*/}
        {/*  react-transition-group*/}
        {/*</button>*/}
        <TabBar
          unselectedTintColor="#949494"
          tintColor="#F36815"
          barTintColor="white"
        >
          {/* 首页 */}
          <TabBar.Item
            title=""
            key="Home"
            icon={
              <img
                src={require("../assets/images/icon/Home.png").default}
                alt={""}
                style={{ width: "0.88rem", height: "0.88rem" }}
              />
            }
            // 选中后的展示图片
            selectedIcon={
              <img
                src={require("../assets/images/icon/Home_Orange.png").default}
                alt={""}
                style={{ width: "0.88rem", height: "0.88rem" }}
              />
            }
            // 是否选中
            selected={pathname === "/" || pathname === "/home/dine"}
            // bar 点击触发，需要自己改变组件 state & selecte={true}
            onPress={() => {
              window.location = "/";
            }}
          >
            {pathname === "/" ? (
              <Route path="/" component={Home} />
            ) : pathname === "/home/dine" ? (
              <Route path="/home/dine" component={Dine} />
            ) : (
              <></>
            )}
          </TabBar.Item>

          {/* Map */}
          {mapBar ? (
            <TabBar.Item
              icon={
                <img
                  src={require("../assets/images/icon/Map.png").default}
                  alt={""}
                  style={{ width: "0.88rem", height: "0.88rem" }}
                />
              }
              selectedIcon={
                <img
                  src={require("../assets/images/icon/Map_Orange.png").default}
                  alt={""}
                  style={{ width: "0.88rem", height: "0.88rem" }}
                />
              }
              title=""
              key="category"
              selected={pathname === "/map"}
              onPress={() => {
                window.location = "/map";
              }}
            >
              <Route path="/map" component={Map} />
            </TabBar.Item>
          ) : (
            ""
          )}

          {/* Shop */}
          {shopBar ? (
            <TabBar.Item
              icon={
                <img
                  src={require("../assets/images/icon/Shop.png").default}
                  alt={""}
                  style={{ width: "0.88rem", height: "0.88rem" }}
                />
              }
              selectedIcon={
                <img
                  src={require("../assets/images/icon/Shop_Orange.png").default}
                  alt={""}
                  style={{ width: "0.88rem", height: "0.88rem" }}
                />
              }
              title=""
              key="category"
              selected={pathname === "/shop"}
              onPress={() => {
                window.location = "/shop";
              }}
            >
              {pathname === "/shop" ? (
                <Route path="/shop" component={Shop} />
              ) : (
                <>else</>
              )}
            </TabBar.Item>
          ) : (
            ""
          )}

          {/* What's on */}
          {whatBar ? (
            <TabBar.Item
              icon={
                <img
                  src={require("../assets/images/icon/WhatsOn.png").default}
                  alt={""}
                  style={{ width: "0.88rem", height: "0.88rem" }}
                />
              }
              selectedIcon={
                <img
                  src={
                    require("../assets/images/icon/WhatsOn_Orange.png").default
                  }
                  alt={""}
                  style={{ width: "0.88rem", height: "0.88rem" }}
                />
              }
              title=""
              key="category"
              selected={pathname === "/what"}
              onPress={() => {
                window.location = "/what";
              }}
            >
              <Route path="/what" component={What} />
            </TabBar.Item>
          ) : (
            ""
          )}

          {/* Help */}
          {helpBar ? (
            <TabBar.Item
              icon={
                <img
                  src={require("../assets/images/icon/Help.png").default}
                  alt={""}
                  style={{ width: "0.88rem", height: "0.88rem" }}
                />
              }
              selectedIcon={
                <img
                  src={require("../assets/images/icon/Help_Orange.png").default}
                  alt={""}
                  style={{ width: "0.88rem", height: "0.88rem" }}
                />
              }
              title=""
              key="category"
              selected={pathname === "/help" || pathname === "/help/call"}
              onPress={() => {
                window.location = "/help";
              }}
            >
              {pathname === "/help" ? (
                <Route path="/help" component={Help} />
              ) : pathname === "/help/call" ? (
                <Route path="/help/call" component={Call} />
              ) : (
                <></>
              )}
            </TabBar.Item>
          ) : (
            ""
          )}

          {/* Rewards */}
          {rewardsBar ? (
            <TabBar.Item
              icon={
                <img
                  src={require("../assets/images/icon/Rewards.png").default}
                  alt={""}
                  style={{ width: "0.88rem", height: "0.88rem" }}
                />
              }
              selectedIcon={
                <img
                  src={
                    require("../assets/images/icon/Rewards_Orange.png").default
                  }
                  alt={""}
                  style={{ width: "0.88rem", height: "0.88rem" }}
                />
              }
              title=""
              key="category"
              selected={pathname === "/rewards"}
              onPress={() => {
                window.location = "/rewards";
              }}
            >
              <Route path="/rewards" component={Rewards} />
            </TabBar.Item>
          ) : (
            ""
          )}

          {/* Info */}
          {infoBar ? (
            <TabBar.Item
              icon={
                <img
                  src={require("../assets/images/icon/Info.png").default}
                  alt={""}
                  style={{ width: "0.88rem", height: "0.88rem" }}
                />
              }
              selectedIcon={
                <img
                  src={require("../assets/images/icon/Info_Orange.png").default}
                  alt={""}
                  style={{ width: "0.88rem", height: "0.88rem" }}
                />
              }
              title=""
              key="category"
              selected={pathname === "/info"}
              onPress={() => {
                window.location = "/info";
              }}
            >
              <Route path="/info" component={Info} />
            </TabBar.Item>
          ) : (
            ""
          )}
        </TabBar>
      </div>
    </Provider>
  );
};

export { MainLayout };

// mapStateToProps：将state映射到组件的props中
// const mapStateToProps = (state) => {
//     return {
//         // languageType: state.languageType,
//         // infoList: state.infoList
//     }
// }
//
// // mapDispatchToProps：将dispatch映射到组件的props中
// const mapDispatchToProps = (dispatch, ownProps) => {
//     return {
//         // setPageTitle (data) {
//         //     // 如果不懂这里的逻辑可查看前面对redux-thunk的介绍
//         //     dispatch(setPageTitle(data))
//         //     // 执行setPageTitle会返回一个函数
//         //     // 这正是redux-thunk的所用之处:异步action
//         //     // 上行代码相当于
//         //     /*dispatch((dispatch, getState) => {
//         //         dispatch({ type: 'SET_PAGE_TITLE', data: data })
//         //     )*/
//         // },
//         // setInfoList (data) {
//         //     dispatch(setInfoList(data))
//         // }
//     }
// }
//
// export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
