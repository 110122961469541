// 获取请求参数
// name：参数名称
export const getQueryParam = (name) => {
    // 只从 url 里获取参数
    let params = window.location.search.substr(1).split('&');
    const query = {};
    for (let i = 0; i < params.length; i++) {
        if (params[i]) {
            const arr = params[i].split('=');
            query[arr[0]] = decodeURIComponent(arr[1]);
        }
    }
    return name ? (query[name] || '') : query;
}

// 本地缓存
export const localCache = (key, data, t) => {
    t = (t || 0) * (1000); // 默认永久

    key = 'DATA_CACHE_' + key;
    let cache;
    if (t < 0) {
        localStorage.removeItem(key);
    }
    // 取值
    else if (typeof data === 'undefined') {
        cache = localStorage.getItem(key);
        if (cache && cache !== 'undefined') {
            cache = JSON.parse(cache);
            if (cache.expire === 0 || cache.expire > (new Date()).getTime()) {
                return cache.data;
            }
        }
        return '';
    } else {
        cache = {
            expire: t > 0 ? (new Date()).getTime() + t : 0,
            data: data
        };

        cache = JSON.stringify(cache);
        localStorage.setItem(key, cache);
    }

}

// 获取APP版本号
export const appVersion = () => {
    let version = getQueryParam('version');
    if (!version) {
        version = localCache('version');
    }

    if (version) {
        // 存储缓存
        localCache('version', version, 5 * 60);
    }
    return version;
}

