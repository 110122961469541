/*--
海皮智造 虚拟键盘 20200930
=============================================
React 内调用说明：
import { VirtualKeyboard } from './VK'
VirtualKeyboard 有2个参数，3个方法。
//
参数1：
VirtualKeyboard.isDisableEnter=true|false
是否禁用Enter键，默认为true。
参数2：
VirtualKeyboard.isDisableTab=true|false
是否禁用Tab键，默认为true。
------------------------
方法1：
.showKeyboardSetState(valueObject,reactComponent)
显示虚拟键盘，此方式主要用于Antd Input组件，由于Antd的输入组件直接修改dom的value不生效，故只能传入值Object对象。
valueObject 是一个Object，Object对象必须使用value属性来传递值。
reactComponent 是当前React Component，用于按键后setState更新显示。
如下例子：
let V={value:'123'}
<Input onClick={()=>VirtualKeyboard.showKeyboardSetState(V,this)} value={V.value}/>
方法2：
.showKeyboard(e)
显示虚拟键盘，此方式主要用于大部分模式。
参数e可是3种类型，
1、字符串，即要联动的dom的id
使用举例： VirtualKeyboard.showKeyboard("inputA")
2、直接是要联动的dom对象，
使用举例： VirtualKeyboard.showKeyboard(document.getElementById("xxx"))
3、鼠标/触摸事件
使用举例： <input onClick={VirtualKeyboard.showKeyboard} />
方法3：
.closeKeyboard()
用命令来关闭键盘，一般来说用不到。
--*/
import React from 'react';
import ReactDOM from 'react-dom';
class VK extends React.Component {
    state={
        isShowNumber: false
    }
    C = [
        {
            code:["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
            width:[, , , , , , , , , ,]
        },
        {
            code:["A", "S", "D", "F", "G", "H", "J", "k", "L"],
            width:[, , , , , , , , ,]
        },
        {
            code:["Z", "X", "C", "V", "B", "N", "M", "Backspace"],
            width:[, , , , , , , 120]
        },
        {
            code:["123", "Space Bar", 'Enter'],
            width:['1.33rem', '2.7rem', '1.3rem']
        }
    ]
    M = [
        {
            code: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
            width:[, , , , , , , , , ,]
        },
        {
            code:["-", "/", ":", ";", "（", "）", "￥", "@", "“","”"],
            width:[, , , , , , , , , ,]
        },
        {
            code:["&", "。", "，", "、", "?", "!", ".", "Backspace"],
            width:[, , , , , , , 120]
        },
        {
            code:["Letter", "Space Bar", 'Enter'],
            width:['1.33rem', '2.7rem', '1.3rem']
        }
    ]
    // W = []
    F_MouseDown = (e) => {
        e = e.nativeEvent
        if (e.button !== 0) return
        if(e.target.className === 'keyboardBox_list' || e.target.className === 'keyboard_close' || e.target.className === 'icon-close'){
            return false
        }
        if (e.target.id.indexOf("VK_") !== 0) {
            if ((e.target.innerText === "Enter" && this.props.values.isDisableEnter === true) || (e.target.innerText === "Tab" && this.props.values.isDisableTab === true)) {
                return
            }
            e.target.style.backgroundColor = "#F36815"
            e.target.style.color = "#707070"
            e.target.style.border = "0.02rem solid #F36815 !important"
            this.V.keydom = e.target
            document.addEventListener("mouseup", this.F_KeyMouseUp);
            return
        }
        document.addEventListener("mousemove", this.F_MouseMove);
        document.addEventListener("mouseup", this.F_MouseUp);
        this.V.posX = -e.pageX
        this.V.posY = -e.pageY
    }
    F_MouseMove = (e) => {
        let tx = this.V.posX + e.pageX + this.V.x;
        let ty = this.V.posY + e.pageY + this.V.y;
        this.dom.style.left = tx + 'px'
        this.dom.style.top = ty + 'px'
    }
    F_MouseUp = (e) => {
        this.V.x = this.V.posX + e.pageX + this.V.x;
        this.V.y = this.V.posY + e.pageY + this.V.y;
        document.removeEventListener("mousemove", this.F_MouseMove);
        document.removeEventListener("mouseup", this.F_MouseUp);
    }
    F_KeyMouseUp = (e, dom) => {
        this.V.keydom.style.backgroundColor = null
        this.V.keydom.style.boxShadow = null
        this.V.keydom.style.color = '#707070'
        this.V.keydom.style.border = "0.02rem solid #707070"
        document.removeEventListener("mouseup", this.F_KeyMouseUp);
    }
    F_TouchStart = (e) => {
        console.log('每一个', e)
        e = e.nativeEvent
        if(e.target.className === 'keyboardBox_list' || e.target.className === 'keyboard_close' || e.target.className === 'icon-close'){
            return false
        }
        if (e.target.id.indexOf("VK_") !== 0) {
            if ((e.target.innerText === "Enter" && this.props.values.isDisableEnter === true) || (e.target.innerText === "Tab" && this.props.values.isDisableTab === true)) {
                return
            }
            e.target.style.backgroundColor = "#F36815"
            e.target.style.color = "#ffffff"
            e.target.style.border = "0.02rem solid #F36815"
            this.V.keydom = e.target
            document.addEventListener("touchend", this.F_KeyTouchEnd);
            return
        }
        document.addEventListener("touchmove", this.F_TouchMove);
        document.addEventListener("touchend", this.F_TouchEnd);
        this.V.posX = -e.targetTouches[0].pageX
        this.V.posY = -e.targetTouches[0].pageY
    }
    F_TouchMove = (e) => {
        let tx = this.V.posX + e.targetTouches[0].pageX + this.V.x;
        let ty = this.V.posY + e.targetTouches[0].pageY + this.V.y;
        this.dom.style.left = tx + 'px'
        this.dom.style.top = ty + 'px'
    }
    F_TouchEnd = (e) => {
        this.V.x = this.V.posX + e.changedTouches[0].pageX + this.V.x;
        this.V.y = this.V.posY + e.changedTouches[0].pageY + this.V.y;
        document.removeEventListener("touchmove", this.F_TouchMove);
        document.removeEventListener("touchend", this.F_TouchEnd);
    }
    F_KeyTouchEnd = (e) => {
        this.V.keydom.style.backgroundColor = "#FFF"
        this.V.keydom.style.boxShadow = null
        this.V.keydom.style.color = '#707070'
        this.V.keydom.style.border = "0.02rem solid #707070"
        document.removeEventListener("touchend", this.F_KeyTouchEnd);
    }
    F_KeyDown = (e) => {
        let dom = e.target
        let txt = dom.innerText
        if (txt === "CapsLock") {
            this.V.isCaps = !this.V.isCaps
            if (this.V.isCaps) {
                dom.style.backgroundColor = "#F36815"
            } else {
                dom.style.backgroundColor = "#FFF"
            }
            this.setState({})
        } else if (txt === "Shift") {
            this.V.isShift = !this.V.isShift
            if (this.V.isShift) {
                dom.style.backgroundColor = "#F36815"
            } else {
                dom.style.backgroundColor = "#FFF"
            }
            this.setState({})
        }  else if (txt === "123") {
            this.setState({isShowNumber: true})
        }   else if (txt === "Letter") {
            this.setState({isShowNumber: false})
        } else if (txt === "Enter") {
            // this.props.handleToClose('')
            this.F_Close()
            // if (this.props.values.isDisableEnter === false) {
            //     this.F_ChangeInput(String.fromCharCode(13))
            // }
        } else if (txt === "Space Bar") {
            this.F_ChangeInput(" ")
        } else if (txt === "Tab") {
            if (this.props.values.isDisableTab === false) {
                this.F_ChangeInput(String.fromCharCode(9))
            }
        } else if (txt === "Backspace") {
            this.F_ChangeInput(-1)
        } else if (txt.indexOf("\n") >= 0) {
            if (this.V.isShift) {
                this.F_ChangeInput(txt[0])
            } else {
                this.F_ChangeInput(txt[2])
            }
        } else {
            if (this.V.isShift) {
                this.F_ChangeInput(txt.toUpperCase() === txt ? txt.toLowerCase() : txt.toUpperCase())
            } else this.F_ChangeInput(txt.toLocaleLowerCase())
        }
    }
    render() {
        let keyStyle = {
            boxSizing: "border-box",
            // position:'absolute',

            // float: "left",
            height: '0.5rem',
            lineHeight: '0.5rem',
            margin: '0 0.025rem 0.05rem',
            borderRadius: '0.08rem',
            border: "0.02rem solid #707070",
            textAlign: "center",
            cursor: "pointer",
            color:'#707070'

        }

        console.log('键盘接收的props',this.props)

        return (
            <div onContextMenu={e => e.preventDefault()} tabIndex="-1" id="VK_Main" ref={dom => this.dom = dom} style={{ outline: 'none', top:0, left: 0, position: 'absolute', zIndex: '999999999', backgroundColor: '#ffffff', padding: '0.2rem 0.73rem 0.2rem', width: "100%", fontSize: 20, border: '0.03rem solid #707070', borderRadius: '0.2rem', userSelect: 'none' }} onMouseDown={this.F_MouseDown} onTouchStart={this.F_TouchStart}>
                <div className="keyboard_close" onClick={this.F_Close}><span className="icon-close" /></div>
                {/*<div id="VK_Keys" style={{ height: '0.5rem', lineHeight: '0.5rem',display:'flex',flexWrap: 'wrap', justifyContent: 'space-between'   }} onClick={this.F_KeyDown} >*/}
                {/*    {this.C.map((c, n) => {*/}
                {/*        if (c.length === 2) {*/}
                {/*            return (<div key={n} style={{ ...keyStyle, width: this.W[n] ? this.W[n] : '0.5rem', lineHeight: '28px', backgroundColor: "#fff" }}>*/}
                {/*                {c[0]}<br />{c[1]}*/}
                {/*            </div>)*/}
                {/*        } else {*/}
                {/*            return <div key={n} style={{ ...keyStyle, width: this.W[n] ? this.W[n] : '0.5rem', lineHeight: '0.5rem', backgroundColor: (c === "Tab" && this.props.values.isDisableTab) || (c === "Enter" && this.props.values.isDisableEnter) ? "#888" : "#fff" }}>{c.length === 1 ? (this.V.isCaps ? c.toUpperCase() : c.toLocaleLowerCase()) : c}</div>*/}
                {/*        }*/}
                {/*    })}*/}
                {/*</div>*/}

                <div id="VK_Keys" className="keyboardBox" >
                    {
                        !this.state.isShowNumber ?
                            <>
                                {this.C.map((item, index) => (
                                    <div className="keyboardBox_list" key={index}>
                                        {item.code.map((item2,index2) => (
                                            <div style={{...keyStyle, width: item.width[index2] ? item.width[index2] : '0.5rem'}} key={index2} onClick={this.F_KeyDown}>
                                                {item2}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </> :
                            <>
                                {this.M.map((item, index) => (
                                    <div className="keyboardBox_list" key={index}>
                                        {item.code.map((item2,index2) => (
                                            <div style={{...keyStyle, width: item.width[index2] ? item.width[index2] : '0.5rem'}} key={index2} onClick={this.F_KeyDown}>
                                                {item2}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </>

                    }

                </div>
            </div>
        )
    }
    constructor(props) {
        super()
        if (props.values.dom !== null) {
            this.V.currentDom = props.values.dom
        }
        if (props.values.value !== null && props.values.state !== null) {
            this.V.reactValueObject = props.values.value
            this.V.reactStateObject = props.values.state
        }
        let w = document.body.clientWidth
        let h = document.body.clientHeight
        this.V.x = w / 2 - 970 / 2
        this.V.y = h - 300
    }
    UNSAFE_componentWillUpdate(props) {
        if (props.values.dom !== null) {
            this.V.currentDom = props.values.dom
            this.V.reactValueObject = null
            this.V.reactStateObject = null
        } else if (props.values.value !== null && props.values.state !== null) {
            this.V.reactValueObject = props.values.value
            this.V.reactStateObject = props.values.state
            this.V.currentDom = null
        }
    }
    componentDidMount() {
        this.dom.focus()
    }
    F_ChangeInput = (c) => {
        let inputContent = ""
        if (this.V.currentDom !== null) {
            inputContent = this.V.currentDom.innerText || this.V.currentDom.textContent || this.V.currentDom.value
        } else inputContent = this.V.reactValueObject.value;
        inputContent = inputContent.toString()
        let strArr = inputContent.split('')
        if (c === -1) {
            strArr.pop()
        } else strArr.push(c)
        if (this.V.currentDom !== null) {
            if (this.V.currentDom.nodeName === "INPUT") {
                this.V.currentDom.value = strArr.join('')
            } else if (this.V.currentDom.nodeName !== undefined) {
                this.V.currentDom.innerHTML = strArr.join('')
            }
        } else if (this.V.reactStateObject !== null && this.V.reactValueObject !== null) {
            this.V.reactValueObject.value = strArr.join('')
            this.V.reactStateObject.setState({})
        }
    }
    V = {
        isCaps: false,
        isShift: false,
        currentDom: null,
        reactValueObject: null,
        reactStateObject: null
    }
    F_Close = () => {
        this.props.values.closeKeyboard()
        this.props.values.state.props.handleToClose('')
        this.props.values.state.V.value = ''
    }
}

const data = {
    VK: VK.prototype,
    div: null,
    props: {}
}

function getParents(el, parentSelector /* optional */) {
    // If no parentSelector defined will bubble up all the way to *document*
    if (parentSelector === undefined) {
        parentSelector = document;
    }

    var parents = [];
    var p = el.parentNode;

    while (p !== parentSelector) {

        var o = p;
        parents.push(o);
        p = o.parentNode;
    }
    parents.push(parentSelector);
    return parents;
}

export const VirtualKeyboard = {
    isDisableEnter: true,
    isDisableTab: true,
    showKeyboardSetState: (valueObject, reactComponent) => {
        if (data.div) VirtualKeyboard.closeKeyboard();
        // 切换tab的key键
        let searchKey = reactComponent.props.searchKey
        data.props.value = valueObject
        data.props.state = reactComponent
        data.props.dom = null
        if (data.div !== null) {
            data.VK.setState({})
        } else {
            data.props.closeKeyboard = VirtualKeyboard.closeKeyboard
            data.props.isDisableEnter = VirtualKeyboard.isDisableEnter
            data.props.isDisableTab = VirtualKeyboard.isDisableTab
            let body = document.getElementsByClassName("keyboard")[searchKey]
            if (body === undefined) return
            let div = document.createElement("div")
            data.div = div
            body.appendChild(div)
            let V = <VK ref={dom => data.VK = dom} values={data.props} />
            ReactDOM.render(V, div)
        }
    },
    showKeyboard: (e) => {
        data.props.value = null
        data.props.state = null
        data.props.dom = null
        if (typeof (e) === "string") {
            let dom = document.getElementById(e)
            if (dom !== null) {
                data.props.dom = dom
            }
        } else if (typeof (e) === "object") {
            if (typeof (e.type) === "string" && typeof (e.nodeName) === "string") {
                data.props.dom = e
            } else if (e.target !== undefined) {
                e = e.target
                if (typeof (e.type) === "string" && typeof (e.nodeName) === "string") {
                    data.props.dom = e
                }
            }
        }
        if (data.props.dom === null) return
        if (data.div !== null) {
            data.VK.setState({})
        } else {
            data.props.closeKeyboard = VirtualKeyboard.closeKeyboard
            data.props.isDisableEnter = VirtualKeyboard.isDisableEnter
            data.props.isDisableTab = VirtualKeyboard.isDisableTab
            let body = getParents(data.props.state).lastChild
            if (body === undefined) return
            let div = document.createElement("div")
            data.div = div
            body.appendChild(div)
            let V = <VK ref={dom => data.VK = dom} values={data.props} />
            ReactDOM.render(V, div)
        }
    },
    closeKeyboard: () => {
        ReactDOM.render(null, data.div)
        data.div.remove()
        data.div = null
    }
}
