import React, {useState, useEffect} from 'react';
import Header from "../../components/header";
import {Modal, Icon, Tabs} from 'antd-mobile';
import Search from "../../components/search"
import $axios from "../../commons/ajax";
const language = localStorage.getItem('languageType')

const Shop = (props) => {
    const [shopList, setShopList] = useState([])
    const [modal1, setModal1] = useState(false)
    const [keyword, setKeyword] = useState('')
    const [storeDetail, setStoreDetail] = useState({
        store_name: {},
        opening_hours: {},
        categories_names: '',
        store_description: {},
        unit_number: {},
        sub_subtitle: {},
        content: {},
        action_button: {}
    })
    const [languageType] = useState(language)
    const [tabs]  = useState([{ title: 'SHOP NAME',tabKey:0 }, { title: 'PRODUCT/CATEGORY',tabKey:1 }, { title: 'LEVEL',tabKey:2 }])
    const [levelList, setLevelList] = useState([])
    const [isShow, setIsShow] = useState(0)
    const [floor, setFloor] = useState('')
    const [categoryList, setCategoryList] = useState([])
    const [mouseState, setMouseState] = useState('selected')
    const [categoryId, setCategoryId] = useState('')
    const [isCategoryActive, setIsCategoryActive] = useState(0)
    const [tabIndex, setTabIndex] = useState(0)
    const [modalMap, setModalMap] = useState(false)
    const [location, setLocation] = useState('')

    // 弹窗打开
    const handleShowModal = (item) => {
        // e.preventDefault(); // 修复 Android 上点击穿透
        setStoreDetail(item)
        setModal1(true)
    }


    // 点击楼层
    const handClickLevelList = (index, item) => {
        console.log('楼层', item)
        if(item.floor === 'ALL LEVELS'){
            setFloor('')
        }else{
            setFloor(item.floor)
        }
        setIsShow(index)
    }

    // 弹窗关闭
    const onClose = () => {
        setModal1(false)
    }

    // 地图弹窗关闭
    const onCloseModalMap = () => {
        setModalMap(false)
    }

    // 点击产品类别
    const handClickCategoryList = (index, item) => {
        setIsCategoryActive(index)
        setCategoryId(item.id)
        setMouseState('selected')
    }

    // 鼠标移入
    const handleMouseOver = (index) => {
        // console.log('鼠标移入', index)
        setIsCategoryActive(index)
        setMouseState('active')
    }

    // 鼠标移出
    const handleMouseOut = (index) => {
        // console.log('鼠标移出', index)
        setIsCategoryActive(index)
        setMouseState('normal')
    }

    // 点击键盘搜索
    const handleToSearch = (data) => {
        // console.log('去搜索', data)
        setKeyword(data)

    }

    // 关闭键盘
    const handleToClose = (data) => {
        setKeyword(data)
    }

    // 展示地图
    const handleShowMap = (location) => {
        setModalMap(true)
        setLocation(location)
    }

    // tab切换
    const handleToChangeTabs = (tab) => {
        setTabIndex(tab.tabKey);
        setKeyword('')
        setCategoryId('')
        setFloor('')
    }

    // 获取楼层
    const getFloor = () => {
        $axios.get('/device/floor').then(
            response => {
                const floor = [
                    {floor: 'ALL LEVELS'},
                    ...response.data.data
                ]
                setLevelList(floor)
            },
            error => {console.log('请求失败', error)}
        )
    }

    // 获取类别
    const getCategory = () => {
        $axios.get('/device/categories',).then(
            response => {
                // console.log('获取类别', response)
                const category = [
                    {
                        title: {cn: "全部",en: "All",mas: "All",தமிழ்: "All"},
                        active: require('../../assets/images/category_1.png').default,
                        selected: require('../../assets/images/category1.png').default,
                        normal: require('../../assets/images/category1_normal.png').default

                    },
                    ...response.data.data.list
                ]
                setCategoryList(category)
            },
            error => {console.log('请求失败', error)}
        )
    }

    // 获取店铺
    const getStore = () => {
        const params = {
            category: categoryId,
            floor: floor,
            keyword: keyword,
            pageNum: 1,
            pageSize: 20
        }
        $axios.get('/device/stores',{params}).then(
            response => {
                // console.log('店铺', response)
                setShopList(response.data.data.list)
            },
            error => {console.log('请求失败', error)}
        )
    }

    useEffect(() => {
        getStore()
        if(!keyword && !isCategoryActive && !floor && !categoryId && !tabIndex){
            getFloor()
            getCategory()
        }
    }, [keyword,floor,categoryId,isCategoryActive])

    return (
        <div>
            {/* 公共头部 */}
            <Header/>

            <div className="shop_container">
                {/* 商家店铺名称列表 */}
                <div className="shop">
                    <div className="shopBox">
                        {shopList.length > 0 && shopList.map((item) => (
                            <div className="shopList" key={item._id} onClick={() => handleShowModal(item)}>
                                <div className="shopList_t">
                                    <img src={item.photo} alt={""} />
                                </div>
                                <div className="shopList_b">
                                    {languageType === 'en' ? item.store_name.en :
                                        languageType === 'cn' ? item.store_name.cn :
                                            languageType === 'mas' ? item.store_name.mas :
                                                languageType === 'தமிழ்' ? item.store_name.தமிழ் : <>else</>
                                    }
                                </div>
                                <div className="shopList_floor">
                                    {item.floor}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>


                {/* 不同类型搜索 */}
                <div className="tabBox">
                    <Tabs
                        tabs={tabs}
                        initialPage={0}
                        animated={false}
                        useOnPan={false}
                        onChange={(tab, index) => { handleToChangeTabs(tab) }}
                    >
                        <div style={{"height":'3.45rem'}}>
                            {/* 搜索 */}
                            {
                                tabIndex === 0 &&
                                <>
                                    <Search handleToSearch={handleToSearch} handleToClose={handleToClose} searchKey={0}/>

                                </>
                            }
                        </div>

                        {/* product/category*/}
                        <div style={{"height":'3.45rem'}}>
                            {/* 搜索 */}
                            {/*<Search handleToSearch={handleToSearch} handleToClose={handleToClose} searchKey={1}/>*/}

                            {
                                tabIndex  === 1  &&
                                <>
                                    <Search handleToSearch={handleToSearch} handleToClose={handleToClose} searchKey={0}/>

                                </>
                            }
                            <div className="category">
                                {
                                    categoryList && categoryList.map((item, index) =>(
                                        <div
                                            key={index}
                                            className={(mouseState === 'selected' && isCategoryActive === index)? 'category_list category_active' : 'category_list'}
                                            onClick={()=> handClickCategoryList(index, item)}
                                            onMouseOver={() => handleMouseOver(index)}
                                            onMouseOut={() => handleMouseOut(index)}
                                        >
                                            <div className="category_icon">
                                                {
                                                    mouseState === 'active' && isCategoryActive === index ? <img src={item.active} alt={""} /> :
                                                        mouseState === 'normal' ? <img src={item.normal} alt={""} /> :
                                                            mouseState === 'selected' && isCategoryActive === index  ? <img src={item.selected} alt={""} /> :
                                                                <img src={item.normal} alt={""} />

                                                }


                                            </div>
                                            <div className="category_name">
                                                {languageType === 'en' ? item.title.en :
                                                    languageType === 'cn' ? item.title.cn :
                                                        languageType === 'mas' ? item.title.mas :
                                                            languageType === 'தமிழ்' ? item.title.தமிழ் : <>else</>
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>

                        {/* Level */}
                        <div>
                            <div className="levelBox">
                                {levelList && levelList.map((item, index) => (
                                    <div
                                        className={isShow === index?"levelBox_active levelBox_list":"levelBox_list"}
                                        key={index}
                                        onClick={()=> handClickLevelList(index, item)}
                                    >{item.floor}</div>
                                ))}
                            </div>
                        </div>
                    </Tabs>
                </div>

            </div>

            {/* 弹窗 */}
            <Modal
                className="detailModal"
                visible={modal1}
                transparent
                maskClosable={true}
                onClose={onClose}
                title=""
                // footer={[{ text: 'Ok', onPress: () => { console.log('ok'); this.onClose('modal1')(); } }]}
                // wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                // afterClose={() => { alert('afterClose'); }}
            >
                <div className="close" onClick={onClose}><Icon type="cross" /></div>

                {/* 图片 */}
                <div className="detailModal_img">
                    <img src={storeDetail.thumbnail} alt={""} />
                </div>

                {/* logo和名称*/}
                <div className="detailModal_top">
                    <img src={storeDetail.photo} alt={""} />
                    <div>
                        <div className="name">
                            <>
                                {languageType === 'en' ? storeDetail.store_name.en :
                                    languageType === 'cn' ? storeDetail.store_name.cn :
                                        languageType === 'mas' ? storeDetail.store_name.mas :
                                            languageType === 'தமிழ்' ? storeDetail.store_name.தமிழ் : <>else</>
                                }
                            </>
                        </div>
                        <div className="time">
                            <>
                                {languageType === 'en' ? storeDetail.unit_number.en :
                                    languageType === 'cn' ? storeDetail.unit_number.cn :
                                        languageType === 'mas' ? storeDetail.unit_number.mas :
                                            languageType === 'தமிழ்' ? storeDetail.unit_number.தமிழ் : <>else</>
                                }
                            </>
                        </div>
                    </div>
                </div>

                {/* 内容 */}
                <div className="detailModal_nr">
                    <div className="lf">
                        <div className="lf_l">
                            {/*operating hours*/}
                            <span>
                                {languageType === 'en' ? storeDetail.opening_hours.en :
                                    languageType === 'cn' ? storeDetail.opening_hours.cn :
                                        languageType === 'mas' ? storeDetail.opening_hours.mas :
                                            languageType === 'தமிழ்' ? storeDetail.opening_hours.தமிழ் : <>else</>
                                }
                            </span>
                        </div>
                        <div className="lf_l">
                            {/*category*/}
                            <span>{storeDetail.categories_names}</span>
                        </div>
                        <div className="lf_l">
                            {/*contact*/}
                            <span>
                                {languageType === 'en' ? storeDetail.sub_subtitle.en :
                                    languageType === 'cn' ? storeDetail.sub_subtitle.cn :
                                        languageType === 'mas' ? storeDetail.sub_subtitle.mas :
                                            languageType === 'தமிழ்' ? storeDetail.sub_subtitle.தமிழ் : <>else</>
                                }
                            </span>
                        </div>
                    </div>
                    <div className="rg">
                        <>
                            {languageType === 'en' ? storeDetail.content.en :
                                languageType === 'cn' ? storeDetail.content.cn :
                                    languageType === 'mas' ? storeDetail.content.mas :
                                        languageType === 'தமிழ்' ? storeDetail.content.தமிழ் : <>else</>
                            }
                        </>
                    </div>

                </div>

                <div className="detailModal_btn" onClick={() => handleShowMap(storeDetail.location)}>
                    {languageType === 'en' ? storeDetail.action_button.en :
                        languageType === 'cn' ? storeDetail.action_button.cn :
                            languageType === 'mas' ? storeDetail.action_button.mas :
                                languageType === 'தமிழ்' ? storeDetail.action_button.தமிழ் : <>else</>
                    }
                </div>

                {/* 图片 */}
                {/*<div className="detailModal_img">*/}
                {/*    localtion*/}
                {/*    <img src={storeDetail.location} alt={""} />*/}
                {/*</div>*/}
            </Modal>


            {/* 弹窗 */}
            <Modal
                className="mapModal"
                visible={modalMap}
                transparent
                maskClosable={true}
                onClose={onCloseModalMap}
                title=""
            >
                <div className="close" onClick={onCloseModalMap}><Icon type="cross" /></div>
                <img src={location} alt={""} />
            </Modal>


        </div>
    );
}

export default (Shop)
