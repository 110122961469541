import React, {useEffect, useState} from 'react';
import Header from "../../components/header";
// import {BASE_URL} from '../../config'
import $axios from "../../commons/ajax";
// import {useHistory} from 'react-router-dom'
// import {Tabs} from "antd-mobile";
// import InfoAbout from "./about";
// import InfoAmenities from "./amenities";
// import InfoParking from "./parking";
// import InfoFaq from "./faq";

const Info = () => {
    const [iframeUrl, setIframeUrl] = useState('')
    const getInfoUrl = () => {
        $axios.get("/device/deviceData").then(
            (response) => {
                if (response.data && response.data.data && response.data.data.name && response.data.data.group) {
                    setIframeUrl(response.data.data.info_url)
                } else {
                    // console.log("pulling name or group is null");
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }
    useEffect(() => {
        getInfoUrl()
    }, [])
    return (
        <div>
            {/* 公共头部 */}
            <Header/>

            <div className="container infoContainer">
                <iframe frameBorder="0" width="100%" height="100%" src={iframeUrl} />
            </div>

        </div>
    );

}

export default Info
