import React, {Component} from 'react';
import { InputItem } from 'antd-mobile';
import { VirtualKeyboard } from '../keyboard/VK';


export default class Search extends Component {

    state = {
        hasError: '',
        value: '',
    }

    V={value:''}

    // 点击搜索框弹出键盘
    handleKeyUp = () => {
        console.log('打开',this,this.V)
        console.log('searchKey',this.props)
        VirtualKeyboard.showKeyboardSetState(this.V, this)
    }

    // 第一次不执行handleToSearch（因为this.V.value是空的）, 第二次数据更新的时候才会去执行handleToSearch搜索
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { handleToSearch } = this.props
        handleToSearch(this.V.value)
    }

    render() {


        return (
            <div className="searchBox">
                <div className="searchStyle">
                    <div className="searchStyle_icon"><img src={require("../../assets/images/searchIcon.png").default} alt={""} /></div>
                    <InputItem
                        className="searchStyle_input"
                        type="phone"
                        placeholder="Search"
                        error={this.state.hasError}
                        // onErrorClick={this.onErrorClick}
                        // onChange={this.props.a(keyword.value)}
                        onClick={() => this.handleKeyUp()}
                        value={this.V.value}
                    />
                    <div className="searchStyle_say" />
                </div>

                <div className="keyboard" id="keyboard">
                </div>
            </div>

        );
    }
}
