import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Typography, Box } from "@material-ui/core/";
import $axios from "../../commons/ajax";
// import {localCache} from "../../utils/tools"

const PairingDisplay = () => {
  const [code, setCode] = useState([]);

  const history = useHistory();
  var polling;
  localStorage.removeItem("pairingCode")
  var pairCode = localStorage.getItem("pairingCode");

  const connect = async () => {
    $axios.get("/device/deviceData").then(
      (response) => {
        console.log("Robot Data", response.data.data);
        if (response.data && response.data.data && response.data.data.name && response.data.data.group) {
          clearInterval(polling);
          history.push(`/welcome`);
          // localCache('info', response.data.data.info_url)
        } else {
          console.log("pulling name or group is null");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(async () => {
    if (pairCode === null) {
      console.log("code", pairCode);
      const Paircode = Math.floor(1000 + Math.random() * 9000);
      setCode(Paircode);
      const params = {
        pairingCode: String(Paircode),
      };
      $axios.post("/device/register", params).then(
        (response) => {
          console.log("注册", response);
          if (response.data.code === 200) {
            console.log("Device Registered");
          }
        },
        (error) => {
          console.log(error);
        }
      );
      localStorage.setItem("pairingCode", Paircode);
    }

    polling = setInterval(connect, 3000);
    setCode(localStorage.getItem("pairingCode"));
    return () => {
      console.log("Unmount");
    };
  }, []);
  return (
    <Container maxWidth="lg">
      <Box width="100%" height="100%">
        <Typography
          variant="h1"
          component="h2"
          align="center"
          color="textSecondary"
          style={{ fontSize: 100, marginTop: 320 }}
        >
          Robot Pairing Code
        </Typography>
        <Typography
          variant="h1"
          component="h2"
          align="center"
          style={{ fontSize: 380, fontWeight: 600, marginTop: 80 }}
        >
          {code}
        </Typography>
      </Box>
    </Container>
  );
};

export default PairingDisplay;
