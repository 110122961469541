import React, {Component} from 'react';
import {Router,Route, Redirect, Switch } from "react-router-dom"
import { createBrowserHistory } from 'history'
import { MainLayout } from './layout/index'
import Welcome from './views/welcome/index'
import Register from './views/register/pairingDisplay'
import $axios from "./commons/ajax";
import {appVersion} from "./utils/tools";
const history = createBrowserHistory()

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            second: 0
        };
    }

    // 手接触屏幕
    handleOnTouchStart = () => {
        console.log('触屏')
        this.setState({second: 0})
        $axios.get('/device/deviceReceive').then(
            response => {
                console.log('触屏返回的结果', response)
            },
            error => {return error}
        )
    }

    // 判断如果用户超过5分钟没有操作页面的话，那么会自动跳转到欢迎页面
    isOperationPage = (time) => {
        // let time = time || 2;    // 默认参数
        const userTime = time*60;
        const objTime = {
            init:0,
            time:function(){
                objTime.init += 1;
                if(objTime.init === userTime){
                    // 用户到达未操作事件页面 做一些处理
                    history.push('/welcome')
                }
            },
            eventFun:function(){
                clearInterval(testUser);
                objTime.init = 0;
                testUser = setInterval(objTime.time,1000);
            }
        }

        let testUser = setInterval(objTime.time,1000);

        const body = document.querySelector('html');
        body.addEventListener("click",objTime.eventFun);
        body.addEventListener("keydown",objTime.eventFun);
        body.addEventListener("onTouchStart",objTime.eventFun);
        body.addEventListener("onTouchMove",objTime.eventFun);
        body.addEventListener("onTouchEnd",objTime.eventFun);
    }


    // 获取对应版本号页面数据
    getVersionData = () => {
        const version = appVersion()
        if(version) {
            const params = {version: version}
            $axios.get('/device/update', {params}).then(
                response => {
                    console.log('结果结果', response)
                },
                error => {return error}
            )
        }

    }


    startTimers = () => {
        setTimeout(() => {
            this.SetTimers();
        }, 5000);
    };

    SetTimers = () => {
        try {
            $axios.get("/device/deviceData").then(
                (response) => {
                    console.log("设备", response);
                    const data = response && response.data && response.data.data || null;
                    console.log("设备", data);
                    if (data && data.name && data.group) {
                    } else {
                        localStorage.removeItem("pairingCode")
                        history.push(`/register`);
                    }
                    this.startTimers();
                },
                (error) => {
                    return error;
                }
            );
        } catch (e) {
            this.startTimers();
        }
    };



    componentDidMount() {
        this.isOperationPage(5)
        this.getVersionData()
        // this.getDeviceData()
        this.SetTimers()
    }

    render() {
        return (
            <div className="rootBox" onTouchStart={this.handleOnTouchStart}>
                <Router history={history}>
                    <Switch>
                        <Route path='/welcome' component={Welcome} />
                        <Route path='/register' component={Register} />
                        {/* MainLayout 中包含了 antd-mobile tabbar */}
                        <Route path='/' component={MainLayout} />
                        {/* 默认跳转到 欢迎 页面 */}
                        <Redirect to="/welcome" component={Welcome}/>
                    </Switch>
                </Router>
            </div>
        );
    }
}
