// state.js

// 声明默认值
// 这里我们列举两个示例
// 同步数据：pageTitle
// 异步数据：infoList（将来用异步接口获取）
//eslint-disable-next-line
export default {
    languageType: 'en',
    pageTitle: '首页',
    infoList: []
}
