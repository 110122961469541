import React, {Component} from 'react';
import Logo from '../../assets/images/logo.png'
import moment from 'moment';
import $axios from "../../commons/ajax";
import {Modal} from "antd-mobile";

export default class Header extends Component {
    state = {
        noticeList: [],
    }

    // SetTimer = () => {
    //     setInterval(() => {
    //         $axios.get('/device/deviceReceives').then(
    //             response => {
    //                 console.log('通知查询', response)
    //                 this.setState({noticeList:response.data.data.list})
    //             },
    //             error => {return error}
    //         )
    //     }, 3000)
    //
    // }

    startTimer = () => {
        setTimeout(() => {
            this.SetTimer()
        }, 5000)
    }

    SetTimer = () => {
        try{
            $axios.get('/device/deviceReceives').then(
                response => {
                    console.log('通知查询', response)
                    const data = response.data.data.list
                    if(data.length !== 0){
                        this.setState({noticeList:data})
                    }
                    this.startTimer()
                },
                error => {return error}
            )

            // $axios.get('/device/deviceObstacleInform').then(
            //     response => {
            //         console.log('通知消息查询', response)
            //         // const data = response.data.data.list
            //         // if(data.length !== 0){
            //         //     this.setState({noticeList:data})
            //         // }
            //         this.startTimer()
            //     },
            //     error => {return error}
            // )
        }catch (e) {
            this.startTimer()
        }
    }

    componentDidMount() {
        this.SetTimer()
    }

    render() {
        return (
            <>
                <div className="header">
                    <div className="headerLeft">
                        <div className="time">{ moment().format('h:mm A')}</div>
                        <div>{ moment().format('dddd,D MMMM YYYY')} </div>
                    </div>
                    <div className="headerCenter">
                        {
                            this.state.noticeList.length === 0 ? '' : this.state.noticeList[0].content
                        }
                    </div>
                    <div className="headerRight">
                        <img src={Logo} alt="" />
                    </div>
                </div>

                {/*<Modal*/}
                {/*    className="detailModal"*/}
                {/*    visible={this.state.modal}*/}
                {/*    transparent*/}
                {/*    maskClosable={false}*/}
                {/*    // onClose={onClose}*/}
                {/*    title=""*/}
                {/*    // footer={[{ text: 'Ok', onPress: () => { console.log('ok'); this.onClose('modal1')(); } }]}*/}
                {/*    // wrapProps={{ onTouchStart: this.onWrapTouchStart }}*/}
                {/*    // afterClose={() => { alert('afterClose'); }}*/}
                {/*>*/}
                {/*    <div>123</div>*/}
                {/*</Modal>*/}
            </>
        );
    }
}
