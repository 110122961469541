import React, { useState, useEffect } from "react";
import Header from "../../components/header/index";
import "./style.scss";
import $axios from "../../commons/ajax";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
// 引入action
import { setLanguageType } from "../../store/actions.js";
import { InputItem } from "antd-mobile";
import {localCache} from "../../utils/tools";

const Welcome = (props) => {
  console.log("接收的props", props);
  const history = useHistory();
  const [languageList, setLanguageList] = useState([]);
  const [mallName, setMallName] = useState('');

  const getMallName = () => {
    var mallID = 0;
    $axios.get("/device/deviceData").then(
      (response) => {
        console.log('商场名称数据', response.data.data)
        mallID = response.data.data.org_id;
        localCache('org_id', mallID)

        const fanData = response.data.data
        if(fanData.device_group !== null){
          setMallName(fanData.device_group.group_name)
        }
      },
      (error) => {
        console.log("请求失败", error);
      }
    );
    $axios.get("/device/orgName").then(
      (response) => {
      },
      (error) => {
        console.log("请求失败", error);
      }
    );
  };

  // 获取语言
  const getLanguage = () => {
    $axios.get("/device/language").then(
      (response) => {
        setLanguageList(response.data.data);
      },
      (error) => {
        console.log("请求失败", error);
      }
    );
  };

  // const startTimer = () => {
  //   setTimeout(() => {
  //     SetTimer();
  //   }, 5000);
  // };
  //
  // const SetTimer = () => {
  //   try {
  //     $axios.get("/device/deviceData").then(
  //       (response) => {
  //         console.log("设备", response);
  //         const data = response && response.data && response.data.data || null;
  //         console.log("设备", data);
  //         if (data && data.name && data.group) {
  //         } else {
  //           localStorage.removeItem("pairingCode")
  //           history.push(`/register`);
  //         }
  //         startTimer();
  //       },
  //       (error) => {
  //         return error;
  //       }
  //     );
  //   } catch (e) {
  //     startTimer();
  //   }
  // };

  // 点击语言跳转首页
  const handleToHome = (item) => {
    console.log("点击语言跳转");
    window.localStorage.setItem("languageType", item.label);
    history.push(`/`);
  };

  useEffect(() => {
    window.localStorage.setItem("enterTag", "firstEnter");
    getLanguage();
    // SetTimer();
    getMallName();
  }, []);

  return (
    <div>
      {/* 公共头部 */}
      <Header />

      <div className="container">
        <div className="welcome_title">Welcome to {mallName}!</div>

        <div className="welcome_cont">
          <div className="cont_tak">
            <div className="txt">
              Hello! My name is Crystal, your friendly neighbourhood guardian.
              Before we begin, please select a language.
            </div>
            {/* 搜索 */}
            {/*<Search />*/}
            <div className="searchBox">
              <div className="searchStyle">
                <div className="searchStyle_icon">
                  <img
                    src={require("../../assets/images/searchIcon.png").default}
                    alt={""}
                  />
                </div>
                <InputItem
                  className="searchStyle_input"
                  type="phone"
                  placeholder="Search"
                />
                <div className="searchStyle_say" />
              </div>
            </div>
          </div>

          <div className="cont_icon">
            <img
              src={require("../../assets/images/welcome_icon.png").default}
              alt={""}
            />
          </div>

          <div className="welcome_lang">
            <div className="lang_tit">Select Language</div>
            <div className="lang_nr">
              {languageList.map((item, index) => (
                <div
                  className="weg"
                  key={index}
                  onClick={() => handleToHome(item)}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// mapStateToProps：将state映射到组件的props中
const mapStateToProps = (state) => {
  console.log("state", state);
  return {
    languageType: state.languageType,
  };
};

// mapDispatchToProps：将dispatch映射到组件的props中
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLanguageType(data) {
      dispatch(setLanguageType(data));
    },
    // setPageTitle (data) {
    //     // 如果不懂这里的逻辑可查看前面对redux-thunk的介绍
    //     dispatch(setPageTitle(data))
    //     // 执行setPageTitle会返回一个函数
    //     // 这正是redux-thunk的所用之处:异步action
    //     // 上行代码相当于
    //     /*dispatch((dispatch, getState) => {
    //         dispatch({ type: 'SET_PAGE_TITLE', data: data })
    //     )*/
    // },
    // setInfoList (data) {
    //     dispatch(setInfoList(data))
    // }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
