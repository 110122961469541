import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import { InputItem } from "antd-mobile";
import { Link } from "react-router-dom";
import Advert from "../../components/advert";
import $axios from "../../commons/ajax";

const Rewards = (props) => {
  const [advertsRewardsTop, setAdvertsRewardsTop] = useState([]);
  const [advertsRewardsFooter, setAdvertsRewardsFooter] = useState([])
  const [mallName, setMallName] = useState('');

  const getMallName = () => {
    var mallID = 0;
    $axios.get("/device/deviceData").then(
      (response) => {
        mallID = response.data.data.org_id;
      },
      (error) => {
        console.log("请求失败", error);
      }
    );
    $axios.get("/device/orgName").then(
      (response) => {
        console.log(response.data.data[mallID]);
        // setMallName(response.data.data[mallID].name);
      },
      (error) => {
        console.log("请求失败", error);
      }
    );
  }

  // 获取广告
  const getAdvertsRewardsTop = async () => {
    $axios.get("/device/advertisingPosition/rewardsTop").then(
        (response) => {
          if (response.data.data !== null) {
            console.log("广告返回的数据", response.data.data);
            setAdvertsRewardsTop(response.data.data);
          } else {
          }
        },
        (error) => {
          console.log("请求失败", error);
        }
    );
  };

  // 获取广告
  const getAdvertsRewardsFooter = async () => {
    $axios.get("/device/advertisingPosition/rewardsFooter").then(
      (response) => {
        if (response.data.data !== null) {
          console.log("广告返回的数据哈哈哈", response.data.data);
          setAdvertsRewardsFooter(response.data.data);
        } else {
        }
      },
      (error) => {
        console.log("请求失败", error);
      }
    );
  };

  useEffect(() => {
    getAdvertsRewardsFooter();
    getAdvertsRewardsTop()
    getMallName();
  }, []);

  return (
    <div>
      {/* 公共头部 */}
      <Header />

      <div className="container">
        <div className="rewardsBox">

          {/* 单张广告位 */}
          <Advert advertsData={advertsRewardsTop} />

          {/* 搜索 */}
          {/*<Search />*/}
          <Link to="/shop" className="searchBox">
            <div className="searchStyle">
              <div className="searchStyle_icon">
                <img
                  src={require("../../assets/images/searchIcon.png").default}
                  alt={""}
                />
              </div>
              <InputItem
                className="searchStyle_input"
                type="phone"
                placeholder="Search"
                // onErrorClick={this.onErrorClick}
                // onChange={this.props.a(keyword.value)}
              />
              <div className="searchStyle_say" />
            </div>
          </Link>

          {/* 轮播广告位 */}
          <Advert advertsData={advertsRewardsFooter}/>
        </div>
      </div>
    </div>
  );
};

export default Rewards;
