import React, {Component} from 'react';
import Header from "../../components/header";
import {Icon, InputItem, Tabs} from "antd-mobile";
// import Search from "../../components/search";
import DineSwiper from "./dineSwiper";
import {Link} from "react-router-dom";

export default class Dine extends Component {
    state = {
        tabs: [
            { title: 'Halal' },
            { title: 'Non Halal' },
            { title: 'Vegetarian' },
        ],
        dineSwiperListOne: [
            {
                id:1,
                bg:require('../../assets/images/dineIm1.png').default,
                logo:require('../../assets/images/dinedf1.png').default,
                mc:require('../../assets/images/dinedf2.png').default,
                mx: 'CRAVE serves up the famous nasi lemak from Adam Road by Selera Rasa, matched with the best teh tarik from Rafee\'s Corner.'
            },
            {
                id:2,
                bg:require('../../assets/images/dineIm1.png').default,
                logo:require('../../assets/images/dinedf1.png').default,
                mc:require('../../assets/images/dinedf2.png').default,
                mx: 'CRAVE serves up the famous nasi lemak from Adam Road by Selera Rasa, matched with the best teh tarik from Rafee\'s Corner.'
            },
            {
                id:3,
                bg:require('../../assets/images/dineIm1.png').default,
                logo:require('../../assets/images/dinedf1.png').default,
                mc:require('../../assets/images/dinedf2.png').default,
                mx: 'CRAVE serves up the famous nasi lemak from Adam Road by Selera Rasa, matched with the best teh tarik from Rafee\'s Corner.'
            }
        ],

        dineSwiperListTwo: [
            {
                id:1,
                bg:require('../../assets/images/dineIm1.png').default,
                logo:require('../../assets/images/dinedf1.png').default,
                mc:require('../../assets/images/dinedf2.png').default,
                mx: 'wewewewewewewewewewe'
            },
            {
                id:2,
                bg:require('../../assets/images/dineIm1.png').default,
                logo:require('../../assets/images/dinedf1.png').default,
                mc:require('../../assets/images/dinedf2.png').default,
                mx: 'dfdfjfhjdfhjfhjdfh'
            }
        ],

        dineSwiperListThree: [
            {
                id:1,
                bg:require('../../assets/images/dineIm1.png').default,
                logo:require('../../assets/images/dinedf1.png').default,
                mc:require('../../assets/images/dinedf2.png').default,
                mx: 'CRAVE serves up the famous nasi lemak from Adam Road by Selera Rasa, matched with the best teh tarik from Rafee\'s Corner.'
            },
        ]
    }
    handleToBack = () => {
        this.props.history.goBack()
    }
    render() {
        console.log('路由组件', this.props)
        return (
            <div>
                {/* 公共头部 */}
                <Header/>

                <div className="containerDine">
                    <div className="publicBack" onClick={this.handleToBack}>
                        <Icon type="left" />Back
                    </div>

                    <div className="dine_title">Dine</div>

                    <div className="tabDineBox">
                        <Tabs
                            tabs={this.state.tabs}
                            initialPage={0}
                            tabBarPosition={'bottom'}
                            animated={false}
                            useOnPan={false}
                            swipeable={false}
                        >
                            <div>
                                <DineSwiper dineSwiperList={this.state.dineSwiperListOne}/>
                            </div>
                            <div>
                                <DineSwiper dineSwiperList={this.state.dineSwiperListTwo}/>
                            </div>
                            <div>
                                <DineSwiper dineSwiperList={this.state.dineSwiperListThree}/>
                            </div>
                        </Tabs>
                    </div>

                    <div className="dine_search">
                        {/* 搜索 */}
                        {/*<Search />*/}
                        <Link to="/shop" className="searchBox">
                            <div className="searchStyle">
                                <div className="searchStyle_icon"><img src={require("../../assets/images/searchIcon.png").default} alt={""} /></div>
                                <InputItem
                                    className="searchStyle_input"
                                    type="phone"
                                    placeholder="Search"
                                    // onErrorClick={this.onErrorClick}
                                    // onChange={this.props.a(keyword.value)}
                                />
                                <div className="searchStyle_say" />
                            </div>
                        </Link>
                    </div>

                </div>

            </div>
        );
    }
}
