import React, { useState, useEffect} from 'react';
import Header from "../../components/header";
import { Tabs } from 'antd-mobile';
import { Rnd } from 'react-rnd'
import $axios from "../../commons/ajax";

function Map(){
    const [isFloorActive, setIsFloorActive] = useState(-1)
    const [isActive, setIsActive] = useState(-1)
    const [showMask, setShowMask] = useState(true)
    const [tabs, setTabs] = useState([])
    const [orgId] = useState(JSON.parse(localStorage.getItem('DATA_CACHE_org_id')).data)
    const [screenSize] =  useState(window.screen.width)
    const [floorPlanList, setFloorPlanList] = useState([])
    const [floorPlanDetail, setFloorPlanDetail] = useState({icon_positions: [],image:{url:''}, floor_icons:[]})

    // 点击图标
    const handClickFloorList = (item, index) => {
        console.log('点击某一发图标', item)
        setIsFloorActive(index)
        floorPlanDetail.icon_positions.map((eve) => {
            if(eve.icon == item.icon){
                setIsActive(item.icon)
            }
        })
    }

    // 楼层tab切换
    const handleToChangeTabs = (tab, index) => {
        console.log('tabs', tab)
        floorPlanList.map((item) => {
            if(item.floor === tab.title){
                setFloorPlanDetail(item)
            }
        })
    }

    // 获取楼层平面图
    const getFloorPlan = () => {
        let displayResolution = ''
        if(screenSize === 768){
            displayResolution = 2
        }else if(screenSize === 1920){
            displayResolution = 1
        }
        const params = {
            org_id: orgId,
            display_resolution: displayResolution,
        }
        $axios.get('/device/displayResolution', {params}).then(
            response => {
                console.log('楼层平面信息', response.data.data)
                const list = response.data.data
                const newFloor = []
                if(list && list.length > 0){
                    console.log('343434')
                    setFloorPlanList(response.data.data)
                    console.log('343434', floorPlanList)
                    setFloorPlanDetail(list[0])
                    list.map((item) => {
                        newFloor.push({title:item.floor})
                    })
                    setTabs(newFloor)
                }
            },
            error => {console.log('请求失败', error)}
        )
    }

    useEffect(() => {
        getFloorPlan()
        setTimeout(function(){
            setShowMask(false)
        },1500)
        console.log('组织Id',orgId, screenSize, typeof screenSize)
    }, []);


    return (
        <div>
            {/* 公共头部 */}
            <Header/>

            <div className="container">
                {/* 楼层分布 */}
                <div className="map">
                    <div className="map_floor">{floorPlanDetail.floor}</div>


                    <div className="mapBox">
                        <Rnd
                            default = { {
                                x : 0 ,
                                y : 0 ,
                                width : '100%' ,
                                height : '4.6rem' ,
                            } }
                        >
                            <div className={floorPlanDetail.display_resolution === '2' ? 'map_nr' : 'map_nr map_nr_pc'}
                                 style={{backgroundImage:`url('${floorPlanDetail.image.url}')`}}>
                                {/*<div className='location'>*/}
                                {/*    You are Here<br/>*/}
                                {/*    <span className="icon icon-location"/>*/}
                                {/*</div>*/}
                                {
                                    floorPlanDetail.icon_positions && floorPlanDetail.icon_positions.length > 0 ?
                                        floorPlanDetail.icon_positions.map((item,index) => (
                                            <div className={isActive === item.icon ? `eve active` : `eve`}
                                                 key={index}
                                                 style={{
                                                     backgroundImage:isActive === item.icon ?`url('${item.active_url}')` : `url('${item.normal_url}')`,
                                                     left:floorPlanDetail.display_resolution === '2' ? (item.x_axis * 400)/100 + 'rem' : (item.x_axis * 420)/100 + 'rem',
                                                     top:floorPlanDetail.display_resolution === '2' ? (item.y_axis * 400)/100 + 'rem' : (item.y_axis * 420)/100 + 'rem',
                                                     width:floorPlanDetail.display_resolution === '2' ? (item.width * 400)/100 + 'rem' : (item.width * 420)/100 + 'rem',
                                                     height:floorPlanDetail.display_resolution === '2' ? (item.height * 400)/100 + 'rem' : (item.height * 420)/100 + 'rem'
                                                 }}>

                                            </div>
                                        ))
                                    : ''
                                }
                            </div>
                        </Rnd>
                    </div>

                    {/* 遮罩层 */}
                    {
                        showMask ?
                        <div className="map_mask">
                            <span className="icon icon-zoom" />
                            Pinch to Zoom
                        </div>
                        : ''
                    }

                </div>

                {/* 楼层列表 */}
                <div className="floorTabs">
                    <Tabs
                        tabs={tabs}
                        initialPage={0}
                        tabBarPosition="left"
                        tabDirection="vertical"
                        animated={false}
                        onChange={(tab,index) => handleToChangeTabs(tab,index)}
                        // useOnPan={false}
                    >
                        {
                            floorPlanList.map((item, index) => (
                                <div className="floorTabs_list" key={index}>
                                    {item.floor_icons && item.floor_icons.length > 0 ?
                                        item.floor_icons.map((item, index) => (
                                            <div
                                                key={index}
                                                className={isFloorActive === index ? 'ic ic_active' : 'ic'}
                                                onClick={() => handClickFloorList(item, index)}
                                            >
                                                <div className="ic_t" >
                                                    <img style={{width:'100%',}} src={isFloorActive === index ? item.active_url : item.normal_url} alt={""} />
                                                </div>
                                                <div className="ic_b">{item.title}</div>
                                            </div>
                                        ))
                                        : ''
                                    }
                                </div>
                            ))
                        }
                    </Tabs>
                </div>
            </div>
        </div>
    );

}

export default Map;
