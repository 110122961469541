import React  from 'react';
import Header from "../../components/header";
import { Link } from 'react-router-dom'

function Help() {
    return (
        <div>
            {/* 公共头部 */}
            <Header/>

            <div className="container">
                <div className="helpBox">
                    <div className="helpBox_title">
                        <span>Getting Help</span>
                        From Certis Security Team
                    </div>
                    <img src={require('../../assets/images/help_img.png').default} alt={""} className="helpBox_img"/>
                    <div className="helpBox_nr">
                        <div className="helpBox_nr_l">
                            <div className="l_tit">CERTIS CISCO</div>
                            <div className="l_nr">
                                <p>Certis is a leading advanced integrated security  organisation that develops and delivers multi- disciplinary security and integrated services.</p>
                                <p>As a unique specialist operations-technology  outsourcing partner, Certis integrates advanced  security, technology, </p>
                                <p>Certis is a leading advanced integrated security  organisation that develops and delivers multi- disciplinary security and integrated services.</p>
                            </div>
                        </div>
                        <div className="helpBox_nr_r">
                            <div className="r_note">
                                <div className="r_note_tit">Note :</div>
                                <div className="r_note_nr">
                                    The security feature<br/>should only be used <br/>for any emergencies.
                                </div>
                            </div>
                            <Link to={"/help/call"} className="r_call">
                                        <span></span>
                                        <p>VIDEO CALL</p>

                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Help
