import React, {Component} from 'react';
import { Icon } from 'antd-mobile';
import Header from "../../components/header";

export default class Call extends Component {
    handleToBack = () => {
        this.props.history.goBack()
    }
    render() {
        return (
            <div>
                {/* 公共头部 */}
                <Header/>

                <div className="container">
                    <div className="publicBack" onClick={this.handleToBack}>
                        <Icon type="left" />Back
                    </div>
                    <div className="callBox">

                    </div>
                    <div className="callBox_btn">
                        <div className="btn_l btn_li"><div className="btn_li_icon"></div>Start Call</div>
                        <div className="btn_r btn_li"><div className="btn_li_icon"></div>end Call</div>
                    </div>
                </div>
            </div>
        );
    }
}
